<template>
  <div class="columns">
    <div class="column is-one-third">
      <card-component
        title="1. Explore groups"
        class="tile is-child"
        :displayHelp="$options.name"
      >
        <validation-observer ref="r_plotgroup_observer" v-slot="{ handleSubmit }">
          <validation-provider
            v-slot="{ errors, valid}"
            :rules= {required:true,integer:true,min_value:1,max_value:nbCP,excluded:axisy}
            :customMessages = "{excluded: 'The 2 axes must be different. Choose another component.'}"
            name="Component to display on X (horizontal)"
          >
            <b-field
              label="Component to display on X (horizontal)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                v-model="axisx"
                expanded
              ></b-input>
            </b-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors, valid}"
            :rules= {required:true,integer:true,min_value:1,max_value:nbCP,excluded:axisx}
            :customMessages = "{excluded: 'The 2 axes must be different. Choose another component.'}"
            name="Component to display on Y (vertical)"
          >
            <b-field
              label="Component to display on Y (vertical)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                v-model="axisy"
                expanded
              ></b-input>
            </b-field>
          </validation-provider>
          <hr />
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click.prevent="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-cogs"></i></span>
              <span> Plot groups </span>
            </b-button>
          </div>
        </validation-observer>
      </card-component>
    </div>
    <div class="column">
      <card-component title="2. Plots"></card-component>
      <error :type='"notifications"' />
      <card-no-result v-if="!resultsLoaded">
      </card-no-result>
      <card-component v-else-if="hasResults">
        <plotly
          v-if="graphicals.length===1"
          :data="graphicals[0]"
        ></plotly>
        <nav class="level">
          <div class="level-left" />
          <div class="buttons level-right">
            <r-save-for-report
              label="Add to report"
              :objectName="datasetName"
              :params="params"
            />
          </div>
        </nav>
      </card-component>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'

export default {
  name: 'RPlotgroup',
  props: {
    datasetName: {
      type: String,
      default: ''
    },
    nbCP: {
      type: Number,
      default: 0
    }
  },
  mixins: [ranalysis],
  components: {
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'r-save-for-report': () => import('@/components/RSaveForReport.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  data () {
    return {
      axisx: 1,
      axisy: 2
    }
  },
  computed: {
    params: function () {
      return {
        'func_name': 'r_plotgroup',
        'datasetName': this.datasetName,
        'axis1': parseInt(this.axisx),
        'axis2': parseInt(this.axisy)
      }
    }
  }
}
</script>
